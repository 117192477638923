/* eslint-disable no-undef */
import '../stylesheets/owl.carousel.css';
import '../stylesheets/owl.theme.default.css';
import '../stylesheets/swiper.css';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.scss';
import '../fonts/flaticon.css';
import '../stylesheets/all.css';

// import '../scripts/jquery';
import $ from 'jquery';
import 'bootstrap';
import './owl.carousel.js';
import './swiper.js';

import Sebastian from '../images/sebastian-hospital.jpg';
import Melbourne from '../images/melbourne-hospital.jpg';
import Barefoot from '../images/barefoot-hospital.jpg';

// Locomotive settings
import './controllers/locomotive-config';
//all sliders
import 'swiper/css/swiper.min.css';

//main components
// import Components from './components/index';
//load images
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

$('.notification-banner .far.fa-times').on('click', function () {
  $('.notification-section').slideUp(300);
});

$('.home-header').removeClass('is-sticky');
$(window).on('scroll', function (event) {
  var scroll = $(window).scrollTop();
  if (scroll < 200) {
    $('.top-menu').removeClass('sticky');
  } else {
    $('.top-menu').addClass('sticky');
  }
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

('use strict');

/*--
            Commons Variables
        -----------------------------------*/
var $window = $(window),
  $body = $('body');

/*--
            Custom script to call Background
            Image & Color from html data attribute
        -----------------------------------*/

$('[data-bg-image]').each(function () {
  var $this = $(this),
    $image = $this.data('bg-image');
  $this.css('background-image', 'url(' + $image + ')');
});
$('[data-bg-color]').each(function () {
  var $this = $(this),
    $color = $this.data('bg-color');
  $this.css('background-color', $color);
});

/*--
            Header Sticky
        -----------------------------------*/
$window.on('scroll', function () {
  if ($window.scrollTop() > 350) {
    $('.sticky-header').addClass('is-sticky');
  } else {
    $('.sticky-header').removeClass('is-sticky');
  }
});

/*--
            Off Canvas Function
        -----------------------------------*/
$('.header-mobile-menu-toggle, .mobile-menu-close').on(
  'click',
  '.toggle, .has-children',
  function () {
    $body.toggleClass('mobile-menu-open');
  }
);
$('.site-mobile-menu').on('click', '.menu-toggle', function (e) {
  e.preventDefault();
  var $this = $(this);
  if ($this.siblings('.sub-menu:visible, .mega-menu:visible').length) {
    $this
      .siblings('.sub-menu, .mega-menu')
      .slideUp()
      .parent()
      .removeClass('open')
      .find('.sub-menu, .mega-menu')
      .slideUp()
      .parent()
      .removeClass('open');
  } else {
    $this
      .siblings('.sub-menu, .mega-menu')
      .slideDown()
      .parent()
      .addClass('open')
      .siblings()
      .find('.sub-menu, .mega-menu')
      .slideUp()
      .parent()
      .removeClass('open');
  }
});

$('li.has-children > a > span').on('hover', function (e) {
  e.preventDefault();
  var $this = $(this);
  if ($this.parent().siblings('.sub-menu:visible, .mega-menu:visible').length) {
    $this
      .parent()
      .siblings('.sub-menu, .mega-menu')
      .slideUp()
      .parent()
      .removeClass('open')
      .find('.sub-menu, .mega-menu')
      .slideUp()
      .parent()
      .removeClass('open');
  } else {
    $this
      .parent()
      .siblings('.sub-menu, .mega-menu')
      .slideDown()
      .parent()
      .addClass('open')
      .siblings()
      .find('.sub-menu, .mega-menu')
      .slideUp()
      .parent()
      .removeClass('open');
  }
});

$('.header-search-toggle').on('click', function (e) {
  e.preventDefault();
  $(this)
    .siblings('.header-search-form, .header-search-form-2')
    .slideToggle()
    .parent()
    .toggleClass('open');
});

$body.on('click', function (e) {
  if (!$(e.target).closest('.header-search').length && $window.width() < 768) {
    $('.header-search-form, .header-search-form-2').slideUp().parent().removeClass('open');
  }
  if (
    !$(e.target).closest('.site-main-mobile-menu-inner').length &&
    !$(e.target).closest('.header-mobile-menu-toggle').length
  ) {
    $body.removeClass('mobile-menu-open');
  }
});

/* -----------------------------------
            Selectric Fake Select Plugin
        -------------------------------------*/

$window.on('load', function () {
  if ($('#map').length > 0) {
    initMap();
  }
});

/*--
            Resize Function
        -----------------------------------*/
$window.resize(function () {});

$(document).ready(function () {
  if ($('.owl-testimonials').length > 0) {
    $('.owl-testimonials').owlCarousel({
      autoplayHoverPause: true,
      loop: true,
      margin: 30,
      autoplay: 2000,
      smartSpeed: 2000,
      dots: true,
      nav: true,
      items: 3,
      responsive: {
        0: {
          items: 1,
          slideBy: 1,
        },
        590: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });
  }

  if ($('.owl-carousel').length > 0) {
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 60,
      pagination: true,
      autoplay: true,
      slideTransition: 'linear',
      autoplayTimeout: 3000,
      autoplaySpeed: 3000,
      autoplayHoverPause: false,
      nav: false,
      dots: false,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  }

  if ($('.intro8-slider').length > 0) {
    // Home 8 Slider
    var intro8Slider = new Swiper('.intro8-slider', {
      autoplay: {
        delay: 8000,
      },
      loop: true,
      speed: 750,
      lazy: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.home11-slider-next',
        prevEl: '.home11-slider-prev',
      },
      //autoplay: {},
    });
  }
});

function initMap() {
  // Basic options for a simple Google Map
  // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
  var mapOptions = {
    // How zoomed in you want the map to start at (always required)
    zoom: 10,
    mapControlType: false,
    streetViewControl: false,
    gestureHandling: 'cooperative',
    panControl: false,
    options: {
      gestureHandling: 'greedy',
    },
    navigationControl: false,
    // The latitude and longitude to center the map (always required)
    center: new google.maps.LatLng(27.9132201, -80.4390837), // New York
  };

  // Get the HTML DOM element that will contain your map
  // We are using a div with id="map" seen below in the <body>
  var mapElement = document.getElementById('map');

  // Create the Google Map using our element and options defined above
  var map = new google.maps.Map(mapElement, mapOptions);

  var InfoObj = [];

  // Let's also add a marker while we're at it
  var markers = [
    {
      position: {
        lat: 27.7532201,
        lng: -80.4390837,
      },
      map: map,
      icon: '/public/pin.png',
      hospitalImage: Sebastian,
      title: 'Sebastian Office',
      address: '8745 N US Highway 1 Sebastian, FL',
      phone: '(772) 217-5362',
    },
    {
      position: {
        lat: 28.0949337,
        lng: -80.6138171,
      },
      map: map,
      icon: '/public/pin.png',
      hospitalImage: Melbourne,
      title: 'Melbourne Office',
      address: '930 S Harbor City Blvd, Melbourne FL',
      phone: '(123) 456-7890',
    },
    {
      position: {
        lat: 27.8831269,
        lng: -80.5103162,
      },
      map: map,
      icon: '/public/pin.png',
      hospitalImage: Barefoot,
      title: 'Barefoot Bay Office',
      address: '311 Barefoot BLVD, Suite 1, Micco FL 32976',
      phone: '(123) 456-7890',
      workingHours: 'M-F 8am to 5pm',
    },
  ];

  function functionAddMarkerInfo() {
    for (var i = 0; i < markers.length; i++) {
      var contentString =
        `
            <div class="map-box">
               <div class="hospital-header">
                  <img src="${markers[i].hospitalImage}"/>
                  <div class="hospital-location">
                  <h3> ${markers[i].title} </h3>
                  <h5><i class="fa fa-map-marker-alt"></i> ${markers[i].address} </h5>
                  <h5><i class="fa fa-mobile-alt-alt"></i> ${markers[i].phone}</h5>` +
        `${
          markers[i].workingHours
            ? `<h5><i class="fa fa-clock"></i> ${markers[i].workingHours}</h5>`
            : ``
        }` +
        ` </div>
              </div>
              <div class="hospital-body">



                <div class="hospital-rating mb-2">
                    <span class="text-muted mr-1">5.0 Rating</span>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
                <a class="text-primary" href="https://www.google.com/search?q=google+review+haryani+dermatology&oq=google+review+haryani+dermatology&aqs=chrome..69i57j69i64l2.3301j0j7&sourceid=chrome&ie=UTF-8#" target="_blank">
                 Check the reviews
                </a>
              </div>
              <i class="fa fa-times infoBox-close"></i>
            </div>
          `;

      const marker = new google.maps.Marker({
        position: {
          lat: markers[i].position.lat,
          lng: markers[i].position.lng,
        },
        icon: markers[i].icon,
        map: map,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: contentString,
      });

      google.maps.event.addListener(
        infoWindow,
        'domready',
        function () {
          $('.infoBox-close').click(function (e) {
            InfoObj[0].set('marker', null);
            InfoObj[0].close();
            InfoObj[0].length = 0;
          });
        },
        { passive: true }
      );

      marker.addListener('click', function () {
        if (InfoObj.length > 0) closeOtherInfo();
        infoWindow.open(marker.get('map'), marker);
        InfoObj[0] = infoWindow;
      });
    }
  }

  function closeOtherInfo() {
    InfoObj[0].set('marker', null);
    InfoObj[0].close();
    InfoObj[0].length = 0;
  }

  functionAddMarkerInfo();
}
